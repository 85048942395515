import { makeStyles } from '@mui/styles';
import THEME from '../config/theme';

export default makeStyles(() => ({
  centerAlignView: {
    backgroundColor: THEME.COLORS.WHITE,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0px'
  },
  formHeader: {
    fontSize: 40,
    fontWeight: 'bold',
    color: THEME.COLORS.DARKGRAY
  },
  signUpImage: {
    height: '100vh',
    width: '68vw',
    position: 'fixed',
    top: 0
  },
  completeProfilePageHeader: {
    fontSize: 32,
    fontWeight: 'bold'
  },
  profileComplitionBackArrow: {
    fontSize: 40,
    color: THEME.COLORS.DARKGRAY,
    cursor: 'pointer'
  },
  statusMessageIconStyles: {
    fontSize: '150px',
    color: '#13C39C'
  }
}));
