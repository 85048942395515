import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  occupationListing: [],
  cityListing: [],
  provinceListing: [],
  plotTypeDropdown: [],
  plotTypeUnitDropdown: [],
  townSectorDropdown: [],
  plotsTownId: '',
  activeRerender: false
};

export const MasterSlice = createSlice({
  name: 'MasterSlice',
  initialState,
  reducers: {
    setOccupationListing: (state, action) => {
      state.occupationListing = action.payload;
    },
    setCityListing: (state, action) => {
      state.cityListing = action.payload;
    },
    setProvinceListing: (state, action) => {
      state.provinceListing = action.payload;
    },
    setPlotTypeDropdown: (state, action) => {
      state.plotTypeDropdown = action.payload;
    },
    setPlotTypeUnitDropdown: (state, action) => {
      state.plotTypeUnitDropdown = action.payload;
    },
    setTownSectorDropdown: (state, action) => {
      state.townSectorDropdown = action.payload;
    },
    setPlotsTownId: (state, action) => {
      state.plotsTownId = action.payload;
    },
    setActiveRerender: (state) => {
      state.activeRerender = !state.activeRerender;
    }
  }
});

export const {
  setOccupationListing,
  setCityListing,
  setPlotTypeDropdown,
  setPlotTypeUnitDropdown,
  setTownSectorDropdown,
  setPlotsTownId,
  setActiveRerender,
  setProvinceListing
} = MasterSlice.actions;

export default MasterSlice.reducer;
