import { get } from 'lodash';
import axios from './axios';
import { setPlotTypeDropdown, setPlotTypeUnitDropdown } from '../reducers/MasterSlice';
import moment from 'moment';
import { appConfig } from 'src/config/config';
import parsePhoneNumber from 'libphonenumber-js/max';

const logoutUserHandler = () => {
  localStorage.clear();
  window.location.href = '/login';
};

const setUserInLocalStorage = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
};

const formateUserPermissions = (responseData) => {
  let formatedData = {};
  if (responseData?.role_permissions?.permissions) {
    formatedData.Role = {
      id: responseData.role_permissions?.id ?? null,
      title: responseData.role_permissions?.title ?? ''
    };
    responseData.role_permissions.permissions.forEach((item) => {
      formatedData = { ...formatedData, [item.permission]: true };
    });
  }
  formatedData.SUPER_ADMIN = responseData.user_type === 1;
  return formatedData;
};

const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

const imageUploader = async (file, mediaType, title) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('media_type', mediaType);
  if (title) {
    formData.append('title', title);
  }
  try {
    const response = await axios.post(`/media/`, formData, {
      rawRequest: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

const multipleImageUploader = async (file, mediaType) => {
  let formData = new FormData();
  Object.keys(file).map((key) => formData.append(`files`, file[key]));
  formData.append('media_type', mediaType);
  try {
    const response = await axios.post(`/media/create_multiple_media`, formData, {
      rawRequest: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

const cnicIdUploader = async (frontCnicId, backCnicId) => {
  try {
    const response = await axios.patch(`/api/updateuserstep4`, {
      cnic_front_media_id: frontCnicId,
      cnic_back_media_id: backCnicId
    });
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

const getPlotTypeList = (controller) => {
  return async (dispatch, getState) => {
    const { plotTypeDropdown } = getState().MasterData;
    if (!plotTypeDropdown.length) {
      try {
        const { data: responseData } = await axios.get('/propertylisting/plot_types', {
          signal: controller.signal
        });
        if (get(responseData, 'status', false)) {
          if (responseData?.data) {
            dispatch(setPlotTypeDropdown(responseData.data));
          }
        }
      } catch (error) {
        if (error.code !== 'ERR_CANCELED') {
          console.log('error', error);
        }
      }
    }
  };
};
const getPlotSizeUnitList = (controller) => {
  return async (dispatch, getState) => {
    const { plotTypeUnitDropdown } = getState().MasterData;
    if (!plotTypeUnitDropdown.length) {
      try {
        const { data: responseData } = await axios.get('/propertylisting/plot_size_units', {
          signal: controller.signal
        });
        if (get(responseData, 'status', false)) {
          if (responseData?.data) {
            const formattedData = responseData.data.map((item) => {
              return {
                id: item.id,
                plot_size_unit: item.plot_size_unit === 'SquareFeet' ? 'Square Feet' : item.plot_size_unit
              };
            });
            dispatch(setPlotTypeUnitDropdown(formattedData));
          }
        }
      } catch (error) {
        if (error.code !== 'ERR_CANCELED') {
          console.log('error', error);
        }
      }
    }
  };
};

const formatSectorsResponse = (data) => {
  let formattedData = [];
  if (data) {
    data.sectors?.forEach((sector) => {
      formattedData.push({
        sectorId: get(sector, 'id', null),
        blocksCount: get(sector, 'blocks_count', 0),
        orgHierarchy: [get(sector, 'sector_name', '')],
        createdAt: sector.created_at ? moment(sector.created_at).format('YYYY-MM-DD') : ''
      });
      sector.blocks?.forEach((block) => {
        formattedData.push({
          sector_Id: get(sector, 'id', null),
          blocksCount: get(sector, 'blocks_count', 0),
          blockId: get(block, 'id', null),
          orgHierarchy: [get(sector, 'sector_name', ''), get(block, 'block_name', '')],
          createdAt: block.created_at ? moment(block.created_at).format('YYYY-MM-DD') : ''
        });
      });
    });
  }
  return formattedData;
};

const formatListingResponse = (data) => {
  if (data) {
    const formattedData = data.map((town) => {
      return {
        id: get(town, 'id', null),
        title: get(town, 'title', '-'),
        cityObj: get(town, 'town_city', '-'),
        city: town.town_city?.name ? town.town_city.name : '-',
        noOfListing: get(town, 'total_plots', '-'),
        reserved: get(town, 'total_reserved_plots', '-'),
        assignedFreelancer: get(town, 'assigned_freelancer', '-'),
        status: town.status,
        createdAt: town.created_at ? moment(town.created_at).format('YYYY-MM-DD') : '',
        plotTypes: get(town, 'plot_types', []),
        totalPlots: get(town, 'total_plots', '0'),
        townCover: town.files[0]?.path_full ? `${appConfig.API_URL}${town.files[0].path_full}` : null,
        listingType: town.type === 'TOWN' ? 'Town' : 'Plaza'
      };
    });
    if (formattedData) {
      return formattedData;
    }
  }
};

const formatAssignedTownList = (data) => {
  if (data) {
    const formattedData = data.map((town) => {
      return {
        id: get(town, 'id', null),
        title: get(town, 'title', ''),
        city: town.town_city?.name ? town.town_city.name : '-',
        noOfListing: get(town, 'total_plots', ''),
        reserved: get(town, 'reserved', ''),
        assignedFreelancer: get(town, 'assigned_freelancer', ''),
        status: town.status,
        createdAt: town.created_at ? moment(town.created_at).format('YYYY-MM-DD') : '',
        plotTypes: get(town, 'plot_types', []),
        totalPlots: get(town, 'total_plots', '0'),
        isLiked: get(town, 'is_favourite', 'false'),
        townCover: town.files[0]?.path_full ? `${appConfig.API_URL}${town.files[0].path_full}` : null,
        type: town.type ? (town.type === 'TOWN' ? 'Town' : 'Plaza') : '-'
      };
    });
    if (formattedData) {
      return formattedData;
    }
  }
};

const formateAllFreelancerDetails = (data) => {
  if (data) {
    const formatedData = data?.map((data) => {
      return {
        id: data.id ? data.id : null,
        name: data.name ? data.name : '-',
        phone: data.mobile_phone ? data.mobile_phone : '-',
        email: data.email ? data.email : '-',
        city: data.user_city?.name ? data.user_city?.name : '-',
        purchases: data.total_sold_plots ? data.total_sold_plots : 0,
        occupation: data.user_occupation?.name ? data.user_occupation?.name : '-',
        totalReserved: data.total_reserved_plots ? data.total_reserved_plots : 0,
        commissionEarned: data.total_commission_earned ? data.total_commission_earned : 0,
        profileImage: data.profile_image ? `${appConfig.API_URL}${data.profile_image.path_full}` : null,
        isActive: data.is_blocked ? 'Inactive' : 'Active',
        userProfileStatus: data.user_profile_status ?? null
      };
    });
    return formatedData;
  }
};

const formateAllSaleAdminDetails = (data) => {
  if (data) {
    const formatedData = data.map((data) => {
      return {
        id: data.id ? data.id : null,
        name: data.name ? data.name : '-',
        phone: data.mobile_phone ? data.mobile_phone : '-',
        email: data.email ? data.email : '-',
        profileImage: data.profile_image ? `${appConfig.API_URL}${data.profile_image.path_full}` : null,
        role: data.role?.title ? data.role.title : '-'
      };
    });
    return formatedData;
  }
};

const formatedFreelancerData = (freelancerData) => {
  return {
    id: freelancerData.id ? freelancerData.id : null,
    name: freelancerData.name ? freelancerData.name : '',
    profileImage: freelancerData.profile_image?.path_full
      ? `${appConfig.API_URL}${freelancerData.profile_image.path_full}`
      : null,

    reserved: optimizeNumber(freelancerData.total_reserved_plots),
    dealDone: optimizeNumber(freelancerData.total_sold_plots),
    commisionEarned: optimizeNumber(freelancerData.total_commission),
    mobilePhone: freelancerData.mobile_phone ?? '',
    cnic: freelancerData.cnic ?? '',
    email: freelancerData.email ?? '',
    occupation: freelancerData.user_occupation?.name ?? '',
    address: freelancerData.address_line1 ?? '',
    userProfileStatus: freelancerData.user_profile_status ?? '',
    isBlocked: freelancerData.is_blocked ?? 0,
    declinedReason: freelancerData.declined_reason ?? '',
    blockedReason: freelancerData.blocked_reason ?? '',
    cnicFrontImage: freelancerData.cnic_front_image?.path_full
      ? `${appConfig.API_URL}${freelancerData.cnic_front_image.path_full}`
      : null,
    cnicBackImage: freelancerData.cnic_back_image?.path_full
      ? `${appConfig.API_URL}${freelancerData.cnic_back_image.path_full}`
      : null,
    reservedPropertiesDetails: freelancerData.properties_details?.reserved
      ? freelancerData.properties_details.reserved.map((plot) => {
          return {
            plotId: plot.id ? plot.id : null,
            plotNo: plot.plot_no ? plot.plot_no : '',
            amount: plot.price ? plot.price : '',
            location: concatString(plot.town_address, plot.town_city?.name),
            reservedFor: plot.reserver_details?.reserver_name ? plot.reserver_details.reserver_name : '-',
            contactId: plot.reserver_details?.contact_id ? plot.reserver_details.contact_id : null,
            startDate: plot.reservation_duration.start_date
              ? moment(plot.reservation_duration.start_date).format('YYYY-MM-DD')
              : '-',
            endDate: plot.reservation_duration.end_date
              ? moment(plot.reservation_duration.end_date).format('YYYY-MM-DD')
              : '-'
          };
        })
      : [],
    soldPropertiesDetails: freelancerData.properties_details?.sold
      ? freelancerData.properties_details.sold.map((plot) => {
          return {
            plotId: plot.id ? plot.id : null,
            plotNo: plot.plot_no ? plot.plot_no : '-',
            soldTo: plot.reserver_details.reserver_name ? plot.reserver_details.reserver_name : '-',
            amount: plot.price ? plot.price : '-',
            location: concatString(plot.town_address, plot.town_city?.name),
            commission: plot.commission ? plot.commission : '-',
            paid: plot.paid ? plot.paid : '-'
          };
        })
      : []
  };
};

const optimizeNumber = (value) => {
  if (value) {
    return value.toString().padStart(2, 0);
  } else {
    return '00';
  }
};

const concatString = (firstValue, secondValue = null) => {
  if (firstValue && secondValue) {
    return firstValue + ',' + secondValue;
  } else if (firstValue) {
    return firstValue;
  } else if (secondValue) {
    return secondValue;
  } else {
    return '-';
  }
};

const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string') {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

const optimizeString = (firstValue, secondValue = null) => {
  if (firstValue && secondValue) {
    return stringEllipsis(firstValue + ',' + secondValue);
  } else if (firstValue) {
    return stringEllipsis(firstValue);
  } else if (secondValue) {
    return stringEllipsis(secondValue);
  } else {
    return '-';
  }
};

const stringEllipsis = (value) => {
  if (value.length > 9) {
    return value.substring(0, 8) + '...';
  } else {
    return value;
  }
};

const abbreviatedString = (value) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(value)) >= 1.0e9
    ? (Math.abs(Number(value)) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(value)) >= 1.0e6
    ? (Math.abs(Number(value)) / 1.0e6).toFixed(2) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(value)) >= 1.0e3
    ? (Math.abs(Number(value)) / 1.0e3).toFixed(2) + 'K'
    : optimizeNumber(value);
};

const sortAscending = (value) => {
  return value.sort((a, b) => a - b);
};

const getDefaultCountry = (countryList, defaultCountry) => {
  return countryList.find((country) => country.code.toLowerCase() === defaultCountry);
};

const checkMobilePhoneValidity = (mobilePhone) => {
  const phoneNumber = parsePhoneNumber(mobilePhone);
  if (phoneNumber?.getType() === 'MOBILE' || phoneNumber?.getType() === 'FIXED_LINE_OR_MOBILE') {
    return true;
  } else {
    return false;
  }
};

const formatAssignedFreelancer = (data) => {
  let freelancerData = [];
  data.map((freelancer) => {
    return freelancerData.push({
      name: freelancer.name,
      id: freelancer.id,
      profileImage: freelancer.profile_image ? `${appConfig.API_URL}${freelancer.profile_image}` : null,
      phone: freelancer.mobile_phone ?? ''
    });
  });
  return freelancerData;
};

const formatAssignedSaleAdmin = (data) => {
  let saleAdminData = [];
  data.map((sales_admin) => {
    return saleAdminData.push({
      name: sales_admin.name,
      id: sales_admin.id,
      profileImage: sales_admin.profile_image ? `${appConfig.API_URL}${sales_admin.profile_image}` : null,
      phone: sales_admin.mobile_phone ?? ''
    });
  });
  return saleAdminData;
};

const formatAllContactsDetails = (data) => {
  if (data) {
    const formatedData = data.map((data) => {
      return {
        id: data.id ? data.id : null,
        name: data.name ? data.name : '-',
        phone: data.mobile_phone ? data.mobile_phone : '-',
        city: data.user_city?.name ? data.user_city.name : '-',
        email: data.email ? data.email : '-',
        reserved: data.reserved_count ? data.reserved_count : 0,
        purchased: data.sold_count ? data.sold_count : 0,
        profileImage: data.profile_image ? `${appConfig.API_URL}${data.profile_image.path_full}` : null,
        freelancerName: data.freelancer?.name ? data.freelancer.name : '-'
      };
    });
    return formatedData;
  }
};
const formatReservedListingsResponse = (data) => {
  if (data) {
    const formattedData = data.map((reservedProperty) => {
      let sizeUnit = '-';
      let type = '-';
      let date = '-';
      let shortStartDate = '-';
      let shortEndDate = '-';
      let completeStartDate = '-';
      let completeEndDate = '-';
      const getShortFormattedDate = (date) => {
        return moment(date).format('DD MMM');
      };
      const getCompleteFormattedDate = (date) => {
        return moment(date).format('LL');
      };
      sizeUnit =
        reservedProperty.plot_size_unit_id === 1
          ? 'Marla'
          : reservedProperty.plot_size_unit_id === 2
          ? 'Kanal'
          : reservedProperty.plot_size_unit_id === 3
          ? 'Square Feet'
          : '-';
      type =
        reservedProperty.plot_type_id === 1
          ? 'Residential'
          : reservedProperty.plot_type_id === 2
          ? 'Commercial'
          : reservedProperty.plot_type_id === 3
          ? 'Agricultural'
          : '-';
      if (reservedProperty.reservation_duration?.start_date) {
        shortStartDate = getShortFormattedDate(reservedProperty.reservation_duration.start_date);
      }
      if (reservedProperty.reservation_duration?.end_date) {
        shortEndDate = getShortFormattedDate(reservedProperty.reservation_duration.end_date);
      }
      date = shortStartDate + '-' + shortEndDate;
      if (reservedProperty.reservation_duration?.start_date) {
        completeStartDate = getCompleteFormattedDate(reservedProperty.reservation_duration.start_date);
      }
      if (reservedProperty.reservation_duration?.end_date) {
        completeEndDate = getCompleteFormattedDate(reservedProperty.reservation_duration.end_date);
      }
      return {
        id: get(reservedProperty, 'id', null),
        name: get(reservedProperty, 'freelancer_name', '-'),
        cityObj: get(reservedProperty, 'town_city', null),
        city: reservedProperty.town_city?.name ? reservedProperty.town_city.name : '-',
        contact: reservedProperty.reserver_details?.reserver_name
          ? reservedProperty.reserver_details.reserver_name
          : '-',
        status: get(reservedProperty, 'plot_status', '-'),
        type: type,
        propertyName: reservedProperty ? (reservedProperty.size ? reservedProperty.size + '-' + sizeUnit : '-') : '-',
        date: date,
        totalAmount: reservedProperty?.price ? priceInCommas(reservedProperty.price) : '-',
        completeStartDate: completeStartDate,
        completeEndDate: completeEndDate,
        reserverPhoneNumber: reservedProperty.reserver_details?.reserver_mobile_phone
          ? reservedProperty.reserver_details.reserver_mobile_phone
          : '-',
        reserverEmail: reservedProperty.reserver_details?.reserver_email
          ? reservedProperty.reserver_details.reserver_email
          : '-',
        reservedFor: get(reservedProperty, 'reserved_for', ''),
        commission: get(reservedProperty, 'commission', 0),
        townName: reservedProperty.town_title ? reservedProperty.town_title : '-',
        plotNo: reservedProperty.plot_no ? reservedProperty.plot_no : '-',
        profileImage: reservedProperty.freelancer_profile_image?.path_full
          ? `${appConfig.API_URL}${reservedProperty.freelancer_profile_image.path_full}`
          : null,
        listingType: reservedProperty?.floor_no ? 'Plaza' : 'Town',
        floorNo: reservedProperty?.floor_no ? reservedProperty.floor_no : '-'
      };
    });
    if (formattedData) {
      return formattedData;
    }
  }
};

const formatReservedPropertyResponse = (reservedProperty) => {
  const getShortFormattedDate = (date) => {
    return moment(date).format('DD MMM');
  };
  const getCompleteFormattedDate = (date) => {
    return moment(date).format('LL');
  };

  return {
    id: get(reservedProperty, 'id', null),
    name: get(reservedProperty, 'freelancer_name', '-'),
    cityObj: get(reservedProperty, 'town_city', null),
    city: reservedProperty.town_city?.name ? reservedProperty.town_city.name : '-',
    contact: reservedProperty.reserver_details?.reserver_name ? reservedProperty.reserver_details.reserver_name : '-',
    status: get(reservedProperty, 'plot_status', '-'),
    type:
      reservedProperty.plot_type_id === 1
        ? 'Residential'
        : reservedProperty.plot_type_id === 2
        ? 'Commercial'
        : reservedProperty.plot_type_id === 3
        ? 'Agricultural'
        : '-',
    propertyName: reservedProperty
      ? reservedProperty.size
        ? reservedProperty.size +
          '-' +
          (reservedProperty.plot_size_unit_id === 1
            ? 'Marla'
            : reservedProperty.plot_size_unit_id === 2
            ? 'Kanal'
            : reservedProperty.plot_size_unit_id === 3
            ? 'Square Feet'
            : '-')
        : '-'
      : '-',
    date:
      (reservedProperty.reservation_duration?.start_date
        ? getShortFormattedDate(reservedProperty.reservation_duration.start_date)
        : '-') +
      '-' +
      (reservedProperty.reservation_duration?.end_date
        ? getShortFormattedDate(reservedProperty.reservation_duration.end_date)
        : '-'),
    totalAmount: reservedProperty?.price ? priceInCommas(reservedProperty.price) : '-',
    completeStartDate: reservedProperty.reservation_duration?.start_date
      ? getCompleteFormattedDate(reservedProperty.reservation_duration.start_date)
      : '-',
    completeEndDate: reservedProperty.reservation_duration?.end_date
      ? getCompleteFormattedDate(reservedProperty.reservation_duration.end_date)
      : '-',
    reserverPhoneNumber: reservedProperty.reserver_details?.reserver_mobile_phone
      ? reservedProperty.reserver_details.reserver_mobile_phone
      : '-',
    reserverEmail: reservedProperty.reserver_details?.reserver_email
      ? reservedProperty.reserver_details.reserver_email
      : '-',
    reservedFor: get(reservedProperty, 'reserved_for', ''),
    commission: reservedProperty?.commission ? priceInCommas(reservedProperty.commission) : 0,
    townName: reservedProperty.town_title ? reservedProperty.town_title : '-',
    plotNo: reservedProperty.plot_no ? reservedProperty.plot_no : '-',
    profileImage: reservedProperty.freelancer_profile_image?.path_full
      ? `${appConfig.API_URL}${reservedProperty.freelancer_profile_image.path_full}`
      : null,
    listingType: reservedProperty?.floor_no ? 'Plaza' : 'Town',
    floorNo: reservedProperty?.floor_no ? reservedProperty.floor_no : '-',
    freelancerName: reservedProperty?.freelancer_name ? reservedProperty.freelancer_name : '-',
    freelancerEmail: reservedProperty?.freelancer_email ? reservedProperty.freelancer_email : '-',
    freelancerPhone: reservedProperty?.freelancer_phone ? reservedProperty.freelancer_phone : '-'
  };
};

function addSizeInImageUrl(url, size) {
  // Extract the file extension
  var extension = url.substring(url.lastIndexOf('.') + 1);

  // Remove the existing extension from the URL
  var urlWithoutExtension = url.replace('.' + extension, '');

  // Add "small" before the extension
  let newUrl = '';
  if (size === 'small') {
    newUrl = urlWithoutExtension + '_small.' + extension;
  } else if (size === 'medium') {
    newUrl = urlWithoutExtension + '_medium.' + extension;
  } else if (size === 'large') {
    newUrl = urlWithoutExtension + '_large.' + extension;
  }

  return newUrl;
}

const priceInCommas = (price) => {
  return price
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatDimensionValues = (data) => {
  const formattedValues = data.map((obj) => obj.value).join(' x ');
  const formattedKeys = data.map((obj) => obj.key).join(' , ');
  return `${formattedValues} ( ${formattedKeys} )`;
};

export {
  logoutUserHandler,
  setUserInLocalStorage,
  formateUserPermissions,
  imageUploader,
  cnicIdUploader,
  errorMessageHandler,
  getPlotTypeList,
  getPlotSizeUnitList,
  getDefaultCountry,
  checkMobilePhoneValidity,
  formatSectorsResponse,
  formatListingResponse,
  formatAssignedTownList,
  formatedFreelancerData,
  formatAssignedFreelancer,
  formatAssignedSaleAdmin,
  formatReservedListingsResponse,
  formateAllFreelancerDetails,
  formateAllSaleAdminDetails,
  optimizeNumber,
  formatAllContactsDetails,
  optimizeString,
  concatString,
  capitalizeFirstLetter,
  sortAscending,
  abbreviatedString,
  multipleImageUploader,
  formatReservedPropertyResponse,
  addSizeInImageUrl,
  priceInCommas,
  formatDimensionValues
};
