import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart/Chart';
import { Helmet } from 'react-helmet-async';
import { Suspense } from 'react';
import { CircularProgress } from './mui';
import generalStyles from '../src/theme/styles';
import { LicenseManager } from 'ag-grid-enterprise';
import GlobalActions from './GlobalActions';

export default function App() {
  LicenseManager.setLicenseKey(
    'For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-7_January_2023_[v2]_MTY3MzA0OTYwMDAwMA==4e3b68258a5d7d2b72a8ddd0cefbc1aa'
  );
  const classes = generalStyles();
  return (
    <>
      <Helmet>
        <title> Real Estate Freelancer </title>
      </Helmet>
      <ThemeProvider>
        <GlobalActions />
        <ScrollToTop />
        <StyledChart />
        <Suspense
          fallback={
            <div className={classes.centerAlignView}>
              <CircularProgress />
            </div>
          }>
          <Router />
        </Suspense>
      </ThemeProvider>
    </>
  );
}
