import { memo } from 'react';
import { SnackbarProvider } from 'notistack';

const SnackNotificationProvider = ({ children }) => {
  return (
    <SnackbarProvider
      hideIconVariant
      maxSnack={3}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
      {children}
    </SnackbarProvider>
  );
};

export default memo(SnackNotificationProvider);
