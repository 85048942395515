import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assignTownList: [],
  favTownList: [],
  fatchFavTown: false,
  loaderState: false
};

export const FreelancerListingSlice = createSlice({
  name: 'FreelancerListingSlice',
  initialState,
  reducers: {
    setFreelancerTownList: (state, action) => {
      state.assignTownList = action.payload;
    },
    setFavTownList: (state, action) => {
      state.favTownList = action.payload;
    },
    setActiveFetchFavTown: (state, action) => {
      state.fatchFavTown = action.payload;
    },
    setLoaderState: (state, action) => {
      state.loaderState = action.payload;
    }
  }
});

export const { setFreelancerTownList, setFavTownList, setActiveFetchFavTown, setLoaderState } =
  FreelancerListingSlice.actions;

export default FreelancerListingSlice.reducer;
