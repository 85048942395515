import { get } from 'lodash';
import { setActiveRerender, setCityListing, setProvinceListing, setOccupationListing } from 'src/reducers/MasterSlice';
import axios from 'src/utils/axios';
import { setUserPermissions, setUserStates } from '../../../reducers/UserSlice';
import { setGlobalLoader, setSnackBarAlert } from '../../../reducers/AlertsSlice';
import { errorMessageHandler, formateUserPermissions } from 'src/utils/helpers';
import { paginationPageSize } from 'src/constants/constants';
import { setPaginationPageData } from 'src/reducers/ListingSlice';
import moment from 'moment';
import { appConfig } from 'src/config/config';
import account from 'src/_mock/account';

export const getUserByToken = (controller) => {
  return async (dispatch) => {
    dispatch(setGlobalLoader(true));
    try {
      const { data: responseData } = await axios.get('/api/userDetail', {
        ...(controller && { signal: controller.signal })
      });
      if (responseData?.status) {
        dispatch(setUserStates(responseData.data));
        dispatch(setUserPermissions(formateUserPermissions(responseData.data)));
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      dispatch(setGlobalLoader(false));
    }
  };
};

export const getOccupationList = (pageNo, filterName = null, signal = null) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      let filters = [];
      if (filterName) {
        filters.push({
          column_name: 'name',
          value: filterName ?? '',
          type: 'like'
        });
      }
      let payload = {
        page: pageNo,
        page_size: paginationPageSize,
        filters: filters
      };
      const { data: responseData } = await axios.post('/occupation/listing', payload, {
        ...(signal && { signal })
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((occupation) => {
            return {
              id: occupation.id ?? '',
              name: occupation.name ?? '',
              description: occupation.description ?? '-'
            };
          });
          let paginationPageData = {
            totalPageCount: responseData.total_page_count ?? 0,
            totalCount: responseData.total_count ?? 0,
            pageSize: responseData.page_size ?? paginationPageSize,
            pageNo: responseData.page ?? 1
          };
          dispatch(setPaginationPageData(paginationPageData));
          dispatch(setOccupationListing(formattedData));
        }
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(
          setSnackBarAlert({
            message: 'Something went wrong!',
            severity: 'error'
          })
        );
      }
    } finally {
      return isSuccess;
    }
  };
};

export const getOccupationDropdownList = () => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get('/occupation/listing');
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((occupation) => {
            return {
              id: occupation.id ?? '',
              name: occupation.name ?? ''
            };
          });
          dispatch(setOccupationListing(formattedData));
        } else {
          dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
          return false;
        }
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(
          setSnackBarAlert({
            message: 'Something went wrong!',
            severity: 'error'
          })
        );
        return false;
      }
    }
  };
};

export const createOccupation = (formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.post('/occupation/create', formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const updateOccupation = (id, formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.patch(`/occupation/update/${id}`, formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const deleteOccupation = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.delete(`/occupation/delete/${id}`);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        dispatch(setActiveRerender());
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    }
  };
};

export const getOccupationById = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get(`/occupation/get_detail/${id}`);
      if (get(responseData, 'status', false)) {
        let formattedData = {
          id: responseData.data.id ?? '',
          name: responseData.data.name ?? '',
          province: responseData.data.province ?? null,
          description: responseData.data.description ?? ''
        };
        return formattedData;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const getCityList = (pageNo, filterValues = null, signal = null) => {
  return async (dispatch) => {
    try {
      let filters = [];
      if (filterValues?.name) {
        filters.push({
          column_name: 'name',
          value: filterValues.name ?? '',
          type: 'like'
        });
      }
      if (filterValues?.province) {
        filters.push({
          column_name: 'province_id',
          value: filterValues.province.id ?? '',
          type: 'exact'
        });
      }
      let payload = {
        page: pageNo,
        page_size: paginationPageSize,
        filters: filters
      };
      const { data: responseData } = await axios.post('/city/listing', payload, {
        ...(signal && { signal })
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((city) => {
            return {
              id: city.id ?? '',
              name: city.name ?? '',
              province: city.province?.name ?? '-',
              description: city.description ?? '-'
            };
          });
          let paginationPageData = {
            totalPageCount: responseData.total_page_count ?? 0,
            totalCount: responseData.total_count ?? 0,
            pageSize: responseData.page_size ?? paginationPageSize,
            pageNo: responseData.page ?? 1
          };
          dispatch(setPaginationPageData(paginationPageData));
          return formattedData;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        return false;
      }
    }
  };
};

export const getCountryList = (pageNo, filterValues = null, signal = null) => {
  return async (dispatch) => {
    try {
      let filters = [];
      if (filterValues?.name) {
        filters.push({
          column_name: 'country_name',
          value: filterValues.name ?? '',
          type: 'like'
        });
      }
      if (filterValues?.countryCode) {
        filters.push({
          column_name: 'country_code',
          value: filterValues.countryCode ?? '',
          type: 'like'
        });
      }
      if (filterValues?.dialCode) {
        filters.push({
          column_name: 'dial_code',
          value: filterValues.dialCode ?? '',
          type: 'like'
        });
      }

      let payload = {
        page: pageNo,
        page_size: paginationPageSize,
        filters: filters
      };
      const { data: responseData } = await axios.post('/countries/listing', payload, {
        ...(signal && { signal })
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((country) => {
            return {
              id: country.id ?? '',
              name: country.country_name ?? '',
              countryCode: country.country_code ?? '',
              dialCode: country.dial_code ?? ''
            };
          });
          let paginationPageData = {
            totalPageCount: responseData.total_page_count ?? 0,
            totalCount: responseData.total_count ?? 0,
            pageSize: responseData.page_size ?? paginationPageSize,
            pageNo: responseData.page ?? 1
          };
          dispatch(setPaginationPageData(paginationPageData));
          return formattedData;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        return false;
      }
    }
  };
};

export const getCityDropdownList = (provinceId) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get(provinceId ? `/city/listing/${provinceId}` : '/city/listing');
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((city) => {
            return {
              id: city.id ?? '',
              name: city.name ?? '',
              province: city.province?.name ?? '',
              description: city.description ?? ''
            };
          });
          dispatch(setCityListing(formattedData));
        }
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
      }
    }
  };
};

export const createCity = (formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.post('/city/create', formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const createCountry = (formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.post('/countries/create', formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const updateCity = (id, formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.patch(`/city/update/${id}`, formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const updateCountry = (id, formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.patch(`/countries/update/${id}`, formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const deleteCity = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.delete(`/city/delete/${id}`);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        dispatch(setActiveRerender());
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    }
  };
};

export const deleteCountry = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.delete(`/countries/delete/${id}`);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        dispatch(setActiveRerender());
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    }
  };
};

export const getCityById = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get(`/city/get_detail/${id}`);
      if (get(responseData, 'status', false)) {
        let formattedData = {
          id: responseData.data.id ?? '',
          name: responseData.data.name ?? '',
          province: responseData.data.province ?? null,
          description: responseData.data.description ?? ''
        };
        return formattedData;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const getCountryById = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get(`/countries/get_detail/${id}`);
      if (get(responseData, 'status', false)) {
        let formattedData = {
          id: responseData.data.id ?? null,
          name: responseData.data.country_name ?? '',
          countryCode: responseData.data.country_code ?? '',
          dialCode: responseData.data.dial_code ?? ''
        };
        return formattedData;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const getProvinceList = () => {
  return async (dispatch, getState) => {
    const { provinceListing } = getState().MasterData;
    if (!provinceListing.length) {
      try {
        const { data: responseData } = await axios.get('/province_listing');
        if (get(responseData, 'status', false)) {
          if (responseData?.data) {
            dispatch(setProvinceListing(responseData.data));
            return responseData.data;
          }
        } else {
          dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
          return false;
        }
      } catch (error) {
        if (error.code !== 'ERR_CANCELED') {
          console.log('error', error);
          dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
          return false;
        }
      }
    }
  };
};
export const getTownDropdownList = (controller) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get('/drop_down/towns', {
        signal: controller.signal
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          return responseData.data;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const getUserDropdownList = (userId, controller) => {
  return async (dispatch) => {
    try {
      const filters = [
        {
          column_name: 'user_type',
          value: userId ?? null,
          type: 'exact'
        }
      ];
      const payload = {
        filters
      };
      const { data: responseData } = await axios.post('/drop_down/users', payload, {
        signal: controller.signal
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          return responseData.data;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const getFeatureList = (pageNo, filterName = null, signal = null) => {
  return async (dispatch) => {
    try {
      let filters = [];
      if (filterName) {
        filters.push({
          column_name: 'property_feature',
          value: filterName ?? '',
          type: 'like'
        });
      }
      let payload = {
        page: pageNo,
        page_size: paginationPageSize,
        filters: filters
      };
      const { data: responseData } = await axios.post('/property_feature/listing', payload, {
        ...(signal && { signal })
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((feature) => {
            return {
              id: feature.id ?? '',
              name: feature.property_feature ?? '',
              description: feature.description ?? '-'
            };
          });
          let paginationPageData = {
            totalPageCount: responseData.total_page_count ?? 0,
            totalCount: responseData.total_count ?? 0,
            pageSize: responseData.page_size ?? paginationPageSize,
            pageNo: responseData.page ?? 1
          };
          dispatch(setPaginationPageData(paginationPageData));
          return formattedData;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(
          setSnackBarAlert({
            message: 'Something went wrong!',
            severity: 'error'
          })
        );
        return false;
      }
    }
  };
};

export const createFeature = (formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.post('/property_feature/create', formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const updateFeature = (id, formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.patch(`/property_feature/update/${id}`, formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const deleteFeature = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.delete(`/property_feature/delete/${id}`);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        dispatch(setActiveRerender());
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    }
  };
};

export const getFeatureById = (id, signal = null) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get(`/property_feature/get_detail/${id}`, {
        ...(signal && { signal })
      });
      if (get(responseData, 'status', false)) {
        let formattedData = {
          id: responseData.data.id ?? '',
          name: responseData.data.property_feature ?? '',
          description: responseData.data.description ?? ''
        };
        return formattedData;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const createUser = (formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.post('/api/admin/sales/create_user', formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};
export const getUsersList = (pageNo, filterValues = null, signal = null) => {
  return async (dispatch) => {
    try {
      let filters = [];
      if (filterValues?.name) {
        filters.push({
          column_name: 'name',
          value: filterValues.name,
          type: 'like'
        });
      }
      if (filterValues?.email) {
        filters.push({
          column_name: 'email',
          value: filterValues.email,
          type: 'like'
        });
      }
      if (filterValues?.phone) {
        filters.push({
          column_name: 'mobile_phone',
          value: '+92' + filterValues.phone,
          type: 'like'
        });
      }
      let payload = {
        page: pageNo,
        page_size: paginationPageSize,
        filters: filters
      };
      const { data: responseData } = await axios.post('/api/admin/sales/listing', payload, {
        ...(signal && { signal })
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((user) => {
            return {
              id: user.id ?? null,
              name: user.name ?? '-',
              phone: user.mobile_phone ?? '-',
              email: user.email ?? '-',
              role: user.role?.title ? user.role.title : '-',
              status: user.is_active ? 'Active' : 'Inactive',
              added: user.created_at ? moment(user.createdAt).format('Do MMMM YYYY') : '-',
              profileImage: user.profile_image?.path_full
                ? appConfig.API_URL + user.profile_image.path_full
                : account.photoURL
            };
          });
          let paginationPageData = {
            totalPageCount: responseData.total_page_count ?? 0,
            totalCount: responseData.total_count ?? 0,
            pageSize: responseData.page_size ?? paginationPageSize,
            pageNo: responseData.page ?? 1
          };
          dispatch(setPaginationPageData(paginationPageData));
          return formattedData;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(
          setSnackBarAlert({
            message: 'Something went wrong!',
            severity: 'error'
          })
        );
      }
      return false;
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.delete(`/api/admin/sales/${id}`);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        dispatch(setActiveRerender());
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    }
  };
};

export const getRoleDropdownList = () => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get('/api/roles');
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = [];
          formattedData = responseData.data.map((role) => {
            return {
              id: role.id ?? '',
              title: role.title ?? ''
            };
          });
          return formattedData;
        } else {
          dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
          return false;
        }
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(
          setSnackBarAlert({
            message: 'Something went wrong!',
            severity: 'error'
          })
        );
        return false;
      }
    }
  };
};

export const getUserById = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get(`/api/admin/sales/${id}`);
      if (get(responseData, 'status', false)) {
        if (responseData.data) {
          const response = responseData.data;
          const role = {
            id: response.role_permissions?.id ? response.role_permissions.id : null,
            title: response.role_permissions?.title ? response.role_permissions.title : ''
          };
          let formattedData = {
            id: response.id ?? null,
            name: response.name ?? '',
            phone: response.mobile_phone ?? '',
            phoneWithoutCC: response.mobile_number_wo_cc ?? '',
            mobilePhone: response.mobile_phone ?? '',
            countryCode: response.country_code ?? '',
            email: response.email ?? '',
            role: role,
            profileImage: response.profile_image?.path_full
              ? appConfig.API_URL + response.profile_image.path_full
              : account.photoURL,
            isActive: response?.is_active ? true : false
          };
          return formattedData;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const updateUser = (id, formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.patch(`/api/admin/sales/update_user/${id}`, formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};
export const getRolesList = (pageNo, filterName = null, signal = null) => {
  return async (dispatch) => {
    try {
      let filters = [];
      if (filterName) {
        filters.push({
          column_name: 'title',
          value: filterName ?? '',
          type: 'like'
        });
      }
      let payload = {
        page: pageNo,
        page_size: paginationPageSize,
        filters: filters
      };
      const { data: responseData } = await axios.post('/api/roles/listing', payload, {
        ...(signal && { signal })
      });
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          let formattedData = responseData.data.map((role) => {
            return {
              id: role.id ?? null,
              name: role.title ?? '-',
              status: role.is_active ? 'Active' : 'Inactive'
            };
          });
          let paginationPageData = {
            totalPageCount: responseData.total_page_count ?? 0,
            totalCount: responseData.total_count ?? 0,
            pageSize: responseData.page_size ?? paginationPageSize,
            pageNo: responseData.page ?? 1
          };
          dispatch(setPaginationPageData(paginationPageData));
          return formattedData;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(
          setSnackBarAlert({
            message: 'Something went wrong!',
            severity: 'error'
          })
        );
        return false;
      }
    }
  };
};

export const getRoleById = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get(`/api/roles/${id}`);
      if (get(responseData, 'status', false)) {
        let formattedData = {
          id: responseData.data.id ?? '',
          title: responseData.data.title ?? '',
          isActive: responseData.data.is_active ? true : false
        };
        let permissionsObj = {};
        if (responseData.data?.rolepermissions) {
          responseData.data.rolepermissions.forEach((item) => {
            permissionsObj = { ...permissionsObj, [item.permission?.permission]: true };
          });
        }
        formattedData.permissions = permissionsObj;
        return formattedData;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
        return false;
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
        return false;
      }
    }
  };
};

export const createRole = (formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.post('/api/roles', formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const updateRole = (id, formatedData) => {
  return async (dispatch) => {
    let isSuccess = false;
    try {
      const { data: responseData } = await axios.put(`/api/roles/${id}`, formatedData);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const deleteRole = (id) => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.delete(`/api/roles/${id}`);
      if (get(responseData, 'status', false)) {
        dispatch(setSnackBarAlert({ message: responseData.message, severity: 'success' }));
        dispatch(setActiveRerender());
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    }
  };
};

export const roleAction = (roleId, is_active) => {
  let isSuccess = false;
  return async (dispatch) => {
    try {
      const payload = {
        is_active
      };
      const { data: response } = await axios.post(`/api/roles/update_status/${roleId}`, payload);
      if (response?.status) {
        dispatch(setSnackBarAlert({ severity: 'success', message: response.message }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const userAction = (userId, is_active) => {
  let isSuccess = false;
  return async (dispatch) => {
    try {
      const payload = {
        is_active
      };
      const { data: response } = await axios.post(`/api/admin/sales/block_unblock_user/${userId}`, payload);
      if (response?.status) {
        dispatch(setSnackBarAlert({ severity: 'success', message: response.message }));
        isSuccess = true;
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    } finally {
      return isSuccess;
    }
  };
};

export const getCountryDropdownList = () => {
  return async (dispatch) => {
    try {
      const { data: responseData } = await axios.get('/contact/get_country_code');
      if (get(responseData, 'status', false)) {
        if (responseData?.data) {
          const formattedData = responseData.data.map((country) => {
            return {
              id: country.id ?? '',
              name: country.country_name ?? '',
              code: country.country_code ?? '',
              dialCode: country.dial_code ?? ''
            };
          });
          return formattedData;
        }
      } else {
        dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
      }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log('error', error);
        dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
      }
    }
  };
};
