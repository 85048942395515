export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const globalTypeDropDown = [
  { key: 'LISTING_TOWN', label: 'Towns' },
  { key: 'LISTING_PROPERTIES', label: 'Plots' },
  { key: 'LISTING_FREELANCER', label: 'Freelancers' },
  { key: 'LISTING_CONTACT', label: 'Contacts' }
];

export const defaultdaysCount = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];

export const propertySizeValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const freelancerList = [
  {
    id: 1,
    name: 'Faizan Choudhary',
    phone: '+92 3038038786'
  },
  {
    id: 2,
    name: 'Faizan Choudhary',
    phone: '+92 3038038786'
  },
  {
    id: 3,
    name: 'Faizan Choudhary',
    phone: '+92 3038038786'
  }
];

export const paginationPageSize = 10;

export const documentList = [
  {
    id: 1,
    name: 'Document File.Jpg',
    size: '290/500kb'
  },
  {
    id: 2,
    name: 'Document File.Jpg',
    size: '290/500kb'
  },
  {
    id: 3,
    name: 'Document File.Jpg',
    size: '290/500kb'
  }
];

export const propertyList = [
  { id: 1, label: 'Plot' },
  { id: 2, label: 'House' }
];

export const plotTypesDropdownList = [
  { id: 1, label: 'Marla' },
  { id: 2, label: 'Kanal' },
  { id: 3, label: 'Square Feet' }
];

export const propertyTypesDropdownList = [
  { id: 1, label: 'Commercial', key: 'commercial' },
  { id: 2, label: 'Residential', key: 'residential' }
];

export const planYearDropdownList = [
  { id: 1, label: '1-Year' },
  { id: 2, label: '2-Year' }
];

export const priceDropdownList = [
  { id: 1, label: '1,00,000' },
  { id: 2, label: '2,00,000' }
];
export const provinces = ['Punjab', 'Sindh', 'Balochistan', 'Khyber Pakhtunkhwa', 'Islamabad Capital Territory'];

export const videoURL = 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4';

export const plotCardData = [
  {
    img: 'https://media.istockphoto.com/photos/modern-residential-buildings-picture-id1225226886?b=1&k=20&m=1225226886&s=612x612&w=0&h=aia_wMFsa344bFlF36Ux-nywNOWlbxHk2BAlSPaJI3E=',
    title: 'Furnished Flats',
    price: '2,00,000'
  },
  {
    img: 'https://media.istockphoto.com/photos/modern-residential-buildings-picture-id1225226886?b=1&k=20&m=1225226886&s=612x612&w=0&h=aia_wMFsa344bFlF36Ux-nywNOWlbxHk2BAlSPaJI3E=',
    title: 'Furnished Flats',
    price: '2,00,000'
  },
  {
    img: 'https://media.istockphoto.com/photos/modern-residential-buildings-picture-id1225226886?b=1&k=20&m=1225226886&s=612x612&w=0&h=aia_wMFsa344bFlF36Ux-nywNOWlbxHk2BAlSPaJI3E=',
    title: 'Furnished Flats',
    price: '2,00,000'
  },
  {
    img: 'https://media.istockphoto.com/photos/modern-residential-buildings-picture-id1225226886?b=1&k=20&m=1225226886&s=612x612&w=0&h=aia_wMFsa344bFlF36Ux-nywNOWlbxHk2BAlSPaJI3E=',
    title: 'Furnished Flats',
    price: '2,00,000'
  },
  {
    img: 'https://media.istockphoto.com/photos/modern-residential-buildings-picture-id1225226886?b=1&k=20&m=1225226886&s=612x612&w=0&h=aia_wMFsa344bFlF36Ux-nywNOWlbxHk2BAlSPaJI3E=',
    title: 'Furnished Flats',
    price: '2,00,000'
  }
];

export const adminDashboardStatsCardData = [
  {
    id: 1,
    key: 'activeProperties',
    img: '/assets/images/dashboardStatsCard/activeProperty.png',
    title: 'Active Property'
  },
  {
    id: 2,
    key: 'soldProperties',
    img: '/assets/images/dashboardStatsCard/soldProperty.png',
    title: 'Properties Sold'
  },
  {
    id: 3,
    key: 'totalRevenue',
    img: '/assets/images/dashboardStatsCard/totalRevenue.png',
    title: 'Total Revenue'
  },
  {
    id: 4,
    key: 'totalPayout',
    img: '/assets/images/dashboardStatsCard/totalPayout.png',
    title: 'Total Payout'
  }
];

export const freelancerDashboardStatsCardData = [
  {
    id: 1,
    key: 'soldProperties',
    title: 'Property Sold'
  },
  {
    id: 2,
    key: 'reservedProperties',
    title: 'In Progress'
  },
  {
    id: 3,
    key: 'cancelledProperties',
    title: 'Cancel'
  }
];

export const commissionStatsCardData = [
  {
    id: 1,
    title: 'Total Commission',
    key: 'totalCommission'
  },
  {
    id: 2,
    title: 'Paid Commission',
    key: 'paidCommission'
  }
];

export const cities = [
  {
    name: 'Lahore'
  },
  {
    name: 'Islamabad'
  },
  {
    name: 'Karachi'
  },
  {
    name: 'RawalPindi'
  },
  {
    name: 'Quetta'
  },
  {
    name: 'Multan'
  },
  {
    name: 'Faisalabad'
  },
  {
    name: 'Vehari'
  },
  {
    name: 'Hyderabad'
  },
  {
    name: 'Okara'
  },
  {
    name: 'Sahiwal'
  },
  {
    name: 'Peshawar'
  },
  {
    name: 'DG Khan'
  },
  {
    name: 'Alipur'
  },
  {
    name: 'Ahmadpur'
  },
  {
    name: 'Attock'
  },
  {
    name: 'Bahawalpur'
  },
  {
    name: 'Bahawalnagar'
  },
  {
    name: 'Chichawatni'
  },
  {
    name: 'Chiniot'
  }
];

export const TownEditModeTabs = [
  { id: '1', label: 'Sector & Plot' },
  { id: '2', label: 'Assign SaleAdmin' },
  { id: '3', label: 'Assign Freelancer' },
  { id: '4', label: 'Basic Details' },
  { id: '5', label: 'Location Highlights' },
  { id: '6', label: 'Video Presentation' },
  { id: '7', label: 'Documents' },
  { id: '8', label: 'Commission' }
];

export const PaymentMethods = [
  { id: 1, label: 'Manual' },
  { id: 2, label: 'Online' },
  { id: 3, label: 'Cheque' }
];

export const Roles = [
  { id: 1, label: 'Admin' },
  { id: 2, label: 'Admin 2' },
  { id: 3, label: 'Admin 3' }
];

export const filterPlotTypeDropDown = [
  { id: 1, label: 'Residential', key: 'residential' },
  { id: 2, label: 'Commercial', key: 'commercial' }
];
export const listingTypes = [
  {
    id: 1,
    title: 'Add New Town',
    description:
      "Find your place with an immersive photo experience and the most listings, including things you won't find anywhere else.",
    buttonText: 'Add New Town'
  },
  {
    id: 2,
    title: 'Add New Plaza/Building',
    description:
      "Find your place with an immersive photo experience and the most listings, including things you won't find anywhere else.",
    buttonText: 'Add New Plaza/Building'
  }
];

export const freelancerDetailsTabs = [
  { id: '1', label: 'Personal Info', key: 'info' },
  { id: '2', label: 'Properties', key: 'properties' },
  { id: '3', label: 'Commission', key: 'commission' },
  { id: '4', label: 'Town & Plaza', key: 'town' },
  { id: '5', label: 'Reservation', key: 'reservation' }
];

export const typeListing = ['Town', 'Plaza'];

export const USERS = {
  SUPER_ADMIN: 1,
  SALE_ADMIN: 2,
  FREELANCER: 3
};
