import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  agGridLoader: false
};

export const AgGridDataLoaderSlice = createSlice({
  name: 'AgGridDataLoaderSlice',
  initialState,
  reducers: {
    setAgGridLoader: (state, action) => {
      state.agGridLoader = action.payload;
    }
  }
});

export const { setAgGridLoader } = AgGridDataLoaderSlice.actions;

export default AgGridDataLoaderSlice.reducer;
