import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CancelIcon, CheckCircleRoundedIcon, CircularProgress } from '../../mui';
import generalStyles from 'src/theme/styles';

const NotificationAlerts = () => {
  const classes = generalStyles();
  const snackBarAlert = useSelector((state) => state.Alerts.snackBarAlert);
  const globalLoader = useSelector((state) => state.Alerts.globalLoader);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const actionRenderer = useCallback(
    (key, severity) => {
      if (severity === 'success') {
        return <CheckCircleRoundedIcon onClick={() => closeSnackbar(key)} sx={{ cursor: 'pointer' }} />;
      } else {
        return <CancelIcon onClick={() => closeSnackbar(key)} sx={{ cursor: 'pointer' }} />;
      }
    },
    [closeSnackbar]
  );

  useEffect(() => {
    if (snackBarAlert) {
      Array.isArray(snackBarAlert.message)
        ? snackBarAlert.message.map((msg) =>
            enqueueSnackbar(msg, {
              variant: snackBarAlert.severity,
              action: (key) => actionRenderer(key, snackBarAlert.severity)
            })
          )
        : enqueueSnackbar(snackBarAlert.message, {
            variant: snackBarAlert.severity,
            action: (key) => actionRenderer(key, snackBarAlert.severity)
          });
    }
  }, [snackBarAlert, actionRenderer, enqueueSnackbar]);

  return globalLoader ? (
    <div className={classes.centerAlignView}>
      <CircularProgress />
    </div>
  ) : null;
};

export default memo(NotificationAlerts);
