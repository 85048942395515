import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null,
  userID: null,
  userType: null,
  userPermissions: null
};

export const UserSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserStates: (state, action) => {
      state.userData = action.payload;
      if (action.payload?.id) {
        state.userID = action.payload.id;
      }
      if (action.payload?.user_type) {
        state.userType = action.payload.user_type;
      }
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
    setUserProfileStatus: (state, action) => {
      state.userData = { ...state.userData, user_profile_status: action.payload };
    }
  }
});

export const { setUserData, setUserStates, setUserPermissions, setUserProfileStatus } = UserSlice.actions;

export default UserSlice.reducer;
