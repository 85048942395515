import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackBarAlert: null,
  globalLoader: false
};

export const AlertsSlice = createSlice({
  name: 'AlertsSlice',
  initialState,
  reducers: {
    setSnackBarAlert: (state, action) => {
      state.snackBarAlert = action.payload;
    },
    setGlobalLoader: (state, action) => {
      state.globalLoader = action.payload;
    }
  }
});

export const { setSnackBarAlert, setGlobalLoader } = AlertsSlice.actions;

export default AlertsSlice.reducer;
