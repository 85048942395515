import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: {},
  notificationFlag: false
};

export const UserSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setNotificationFlag: (state, action) => {
      state.notificationFlag = action.payload;
    }
  }
});

export const { setNotifications, setNotificationFlag } = UserSlice.actions;

export default UserSlice.reducer;
