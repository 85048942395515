import { Navigate, useRoutes } from 'react-router-dom';
import { lazy } from 'react';
import { useSelector } from 'react-redux';

const Listing = lazy(() => import('./pages/listing/Listing'));
const ActiveProperties = lazy(() => import('./pages/dashboard/ActiveProperties'));
const SoldProperties = lazy(() => import('./pages/dashboard/SoldProperties'));
const PayoutAndRevenue = lazy(() => import('./pages/dashboard/PayoutAndRevenue'));
const CountriesSettings = lazy(() => import('./pages/settings/CountriesSettings'));
const AddTown = lazy(() => import('./pages/listing/AddTown/AddTown'));
const LoginPage = lazy(() => import('./pages/login/LoginPage'));
const Page404 = lazy(() => import('./pages/Page404'));
const Page403 = lazy(() => import('./pages/page403'));
const DashboardAppPage = lazy(() => import('./pages/dashboard/DashboardAppPage'));
const ForgotPasswordPage = lazy(() => import('./pages/login/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/login/ResetPasswordPage'));
const RegisterUserPage = lazy(() => import('./pages/registerUser/RegisterUserPage'));
const SuccessPage = lazy(() => import('./pages/registerUser/SuccessPage'));
const CompleteProfilePage = lazy(() => import('./pages/completeProfile/CompleteProfilePage'));
const AuthGuard = lazy(() => import('./sections/auth/guards/AuthGuard'));
const TownPreview = lazy(() => import('./pages/listing/TownPreview/Town'));
const Contact = lazy(() => import('./freelancer/Contact/Contact'));
const ContactForm = lazy(() => import('./freelancer/Contact/ContactForm'));
const UpdateContact = lazy(() => import('./freelancer/Contact/UpdateContact'));
const ContactDetails = lazy(() => import('./freelancer/Contact/ContactDetails'));
const FreelancerListing = lazy(() => import('./freelancer/Listing/FreelancerListing'));
const ReservedPropertiesListing = lazy(() => import('./pages/reservedListing/ReservedPropertiesListing'));
const ReservePlot = lazy(() => import('./freelancer/ReservePlot/ReservePlot'));
const Freelancers = lazy(() => import('./pages/freelancersListing/ListingFreelancers'));
const FreelancersDetails = lazy(() => import('./pages/freelancersListing/FreelancersDetails'));
const SettingsPage = lazy(() => import('./pages/settings/SettingsPage'));
const SettingsComingSoonPage = lazy(() => import('./pages/settings/ComingSoonPage'));
const AccountSetting = lazy(() => import('./pages/settings/AccountSetting'));
const SecuritySetting = lazy(() => import('./pages/settings/SecuritySetting'));
const DocumentSetting = lazy(() => import('./pages/settings/DocumentsSettings'));
const NotificationSetting = lazy(() => import('./pages/settings/NotificationSetting'));
const Notification = lazy(() => import('./pages/notifications/Notification'));
const CitiesSetting = lazy(() => import('./pages/settings/CitiesSetting'));
const CitySettingForm = lazy(() => import('./pages/settings/Forms/CitySettingForm'));
const CountrySettingForm = lazy(() => import('./pages/settings/Forms/CountrySettingForm'));
const OccupationSetting = lazy(() => import('./pages/settings/OccupationSetting'));
const OccupationSettingForm = lazy(() => import('./pages/settings/Forms/OccupationSettingForm'));
const UserSetting = lazy(() => import('./pages/settings/UserSetting'));
const UserSettingForm = lazy(() => import('./pages/settings/Forms/UserSettingForm'));
const RoleSetting = lazy(() => import('./pages/settings/RoleSetting'));
const RoleSettingForm = lazy(() => import('./pages/settings/Forms/RoleSettingForm'));
const ReservationSetting = lazy(() => import('./pages/settings/ReservationSetting'));
const FreelancerReservationSetting = lazy(() => import('./pages/settings/FreelancerReservationSetting'));
const FeatureSetting = lazy(() => import('./pages/settings/FeatureSetting'));
const FeatureSettingForm = lazy(() => import('./pages/settings/Forms/FeatureSettingForm'));
const ManagementPage = lazy(() => import('./pages/cms/ManagementPage'));
const ServicesPage = lazy(() => import('./pages/cms/ServicesPage'));
const ServicesForm = lazy(() => import('./pages/cms/Forms/ServicesForm'));
const ContentReplacementPage = lazy(() => import('./pages/cms/ContentReplacementPage'));
const MediaPage = lazy(() => import('./pages/cms/MediaPage'));
const Portfolio = lazy(() => import('./pages/portfolio/Portfolio'));
const FreelancerCommission = lazy(() => import('./freelancer/Commission/FreelancerCommission'));
const DashboardLayout = lazy(() => import('./layouts/dashboard'));
const ListingType = lazy(() => import('./pages/listing/ListingType'));
const SettingsUserDetailPage = lazy(() => import('./pages/settings/SettingsUserDetailPage'));
const ReservedPropertyDetails = lazy(() => import('./pages/reservedListing/ReservedPropertyDetails'));
const SearchPage = lazy(() => import('./pages/search/Search'));

const adminRoutes = [
  { element: <Navigate to="/dashboard" />, index: true },
  { path: 'dashboard', element: <DashboardAppPage /> },
  { path: 'listing', element: <Listing /> },
  { path: 'freeLancers', element: <Freelancers /> },
  { path: 'freeLancers/freelancerDetail/:id', element: <FreelancersDetails /> },
  { path: 'settings', element: <SettingsPage /> },
  { path: 'settings/accountSetting', element: <AccountSetting /> },
  { path: 'settings/securitySetting', element: <SecuritySetting /> },
  { path: 'settings/addUserSetting', element: <SettingsComingSoonPage /> },
  { path: 'settings/users', element: <UserSetting /> },
  { path: 'settings/users/form', element: <UserSettingForm /> },
  { path: 'settings/users/userDetails/:id', element: <SettingsUserDetailPage /> },
  { path: 'settings/roles', element: <RoleSetting /> },
  { path: 'settings/roles/form', element: <RoleSettingForm /> },
  { path: 'settings/citiesSetting', element: <CitiesSetting /> },
  { path: 'settings/countriesSetting', element: <CountriesSettings /> },
  { path: 'settings/citiesSetting/form', element: <CitySettingForm /> },
  { path: 'settings/countriesSetting/form', element: <CountrySettingForm /> },
  { path: 'settings/occupationSetting', element: <OccupationSetting /> },
  { path: 'settings/occupationSetting/form', element: <OccupationSettingForm /> },
  { path: 'settings/featureSetting', element: <FeatureSetting /> },
  { path: 'settings/featureSetting/form', element: <FeatureSettingForm /> },
  { path: 'settings/reservationSetting', element: <ReservationSetting /> },
  { path: 'contentManagementSystem', element: <ManagementPage /> },
  { path: 'contentManagementSystem/services', element: <ServicesPage /> },
  { path: 'contentManagementSystem/services/form', element: <ServicesForm /> },
  { path: 'contentManagementSystem/ckEditor', element: <ContentReplacementPage /> },
  { path: 'contentManagementSystem/media', element: <MediaPage /> },
  { path: 'listing/addTown', element: <AddTown /> },
  { path: 'listing/listingPreview/:id', element: <TownPreview /> },
  { path: 'contacts/contactDetails/:id', element: <ContactDetails /> },
  { path: 'contacts', element: <Contact /> },
  { path: 'contacts/updateContact', element: <UpdateContact /> },
  { path: 'reservedListing', element: <ReservedPropertiesListing /> },
  { path: 'notifications', element: <Notification /> },
  { path: 'listing/addPlazaBuilding', element: <AddTown /> },
  { path: 'listing/listingType', element: <ListingType /> },
  { path: 'reservedListing/reservedPropertyDetails', element: <ReservedPropertyDetails /> },
  { path: 'dashboard/activeProperties', element: <ActiveProperties /> },
  { path: 'dashboard/soldProperties', element: <SoldProperties /> },
  { path: 'dashboard/totalPayout', element: <PayoutAndRevenue /> },
  { path: 'dashboard/totalRevenue', element: <PayoutAndRevenue /> },
  { path: 'search', element: <SearchPage /> }
];

const userRoutes = [
  { element: <Navigate to="/dashboard" />, index: true },
  { path: 'dashboard', element: <DashboardAppPage /> },
  { path: 'portfolio', element: <Portfolio /> },
  { path: 'commission', element: <FreelancerCommission /> },
  { path: 'freelancerSettings', element: <SettingsPage /> },
  { path: 'freelancerSettings/accountSetting', element: <AccountSetting /> },
  { path: 'freelancerSettings/reservationSetting', element: <FreelancerReservationSetting /> },
  { path: 'freelancerSettings/securitySetting', element: <SecuritySetting /> },
  { path: 'freelancerSettings/documentsSetting', element: <DocumentSetting /> },
  { path: 'contacts', element: <Contact /> },
  { path: 'contacts/updateContact', element: <UpdateContact /> },
  { path: 'contacts/addContact', element: <ContactForm /> },
  { path: 'notifications', element: <Notification /> },
  { path: 'contacts/contactDetails/:id', element: <ContactDetails /> },
  { path: 'freelancerListing', element: <FreelancerListing /> },
  { path: 'freelancerListing/listingPreview/:id', element: <TownPreview /> },
  { path: 'freelancerListing/reservePlot', element: <ReservePlot /> },
  { path: 'freelancerSettings/notificationSetting', element: <NotificationSetting /> },
  { path: 'portfolio/reservedPropertyDetails', element: <ReservedPropertyDetails /> }
];

export default function Router() {
  const userType = useSelector((state) => state.User.userType);
  const accessToken = localStorage.getItem('accessToken');

  let currentRoutes = [];
  if (userType === 1 || userType === 2) currentRoutes = adminRoutes;
  else if (userType === 3) currentRoutes = userRoutes;
  if (!accessToken) currentRoutes = [{ element: <Navigate to="/login" />, index: true }];

  const routes = useRoutes([
    {
      path: currentRoutes.path,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: currentRoutes
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/forgotpassword',
      element: <ForgotPasswordPage />
    },
    {
      path: '/resetpassword',
      element: <ResetPasswordPage />
    },
    {
      path: '/registerUser',
      element: <RegisterUserPage />
    },
    {
      path: '/registerSuccessfully',
      element: <SuccessPage />
    },
    {
      path: '/completeProfile',
      element: (
        <AuthGuard>
          <CompleteProfilePage />
        </AuthGuard>
      )
    },
    {
      path: '/unauthorized',
      element: <Page403 />
    },
    {
      path: '*',
      element: <Page404 />
    }
  ]);

  return routes;
}
