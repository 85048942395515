import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageName: '',
  showGlobalSearch: false,
  globalSearch: null
};

export const HeaderSlice = createSlice({
  name: 'HeaderSlice',
  initialState,
  reducers: {
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },
    setActiveGlobalSearch: (state, action) => {
      state.showGlobalSearch = action.payload;
    },
    setGlobalSearch: (state, action) => {
      state.globalSearch = action.payload;
    }
  }
});

export const { setPageName, setActiveGlobalSearch, setGlobalSearch } = HeaderSlice.actions;

export default HeaderSlice.reducer;
