import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  townPreviewData: {},
  freelancerAssigneeList: [],
  saleAdminAssigneeList: [],
  freelancerData: [],
  paginationPageData: {},
  userStats: {},
  sectorsData: [],
  selectedFreelancer: {},
  selectedPlots: [],
  checkedPlots: [],
  plotFeatures: [],
  updatePlotId: null,
  switchPlotFormState: false
};

export const ListingSlice = createSlice({
  name: 'ListingSlice',
  initialState,
  reducers: {
    setTownPreviewData: (state, action) => {
      state.townPreviewData = action.payload;
    },
    setFreelancerAssigneeList: (state, action) => {
      state.freelancerAssigneeList = action.payload;
    },
    setSaleAdminAssigneeList: (state, action) => {
      state.saleAdminAssigneeList = action.payload;
    },
    setFreelancerData: (state, action) => {
      state.freelancerData = action.payload;
    },
    setPaginationPageData: (state, action) => {
      state.paginationPageData = action.payload;
    },
    setUserStats: (state, action) => {
      state.userStats = action.payload;
    },
    setSectorsData: (state, action) => {
      state.sectorsData = action.payload;
    },
    setSelectedFreelancerData: (state, action) => {
      state.selectedFreelancer = action.payload;
    },
    setSelectedPlots: (state, action) => {
      state.selectedPlots = action.payload;
    },
    setCheckedPlots: (state, action) => {
      state.checkedPlots = action.payload;
    },
    setUpdatePlotId: (state, action) => {
      state.updatePlotId = action.payload;
    },
    setPlotFeatures: (state, action) => {
      state.plotFeatures = action.payload;
    },
    setSwitchPlotFormState: (state, action) => {
      state.switchPlotFormState = action.payload;
    }
  }
});

export const {
  setTownPreviewData,
  setFreelancerAssigneeList,
  setSaleAdminAssigneeList,
  setFreelancerData,
  setPaginationPageData,
  setUserStats,
  setSectorsData,
  setSelectedFreelancerData,
  setSelectedPlots,
  setCheckedPlots,
  setUpdatePlotId,
  setPlotFeatures,
  setSwitchPlotFormState
} = ListingSlice.actions;

export default ListingSlice.reducer;
