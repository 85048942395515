import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NotificationAlerts from './components/alerts/NotificationAlerts';
import { getUserByToken } from './pages/settings/Api/MasterApiCalls';
import { useNavigate } from 'react-router-dom';

const GlobalActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      dispatch(getUserByToken());
    }
  }, [dispatch, navigate]);

  return <NotificationAlerts />;
};

export default GlobalActions;
