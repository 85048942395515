import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import MasterSlice from 'src/reducers/MasterSlice';
import HeaderSlice from '../reducers/HeaderSlice';
import UserSlice from '../reducers/UserSlice';
import AlertsSlice from '../reducers/AlertsSlice';
import ListingSlice from '../reducers/ListingSlice';
import FreelancerListingSlice from '../reducers/FreelancerListingSlice';
import AgGridDataLoaderSlice from 'src/reducers/AgGridDataLoaderSlice';
import NotificationSlice from 'src/reducers/NotificationSlice';

const rootReducer = combineReducers({
  Header: HeaderSlice,
  User: UserSlice,
  MasterData: MasterSlice,
  Alerts: AlertsSlice,
  Listing: ListingSlice,
  FreelancerListingSlice: FreelancerListingSlice,
  gridLoaderSlice: AgGridDataLoaderSlice,
  Notification: NotificationSlice
});

export const store = configureStore({
  reducer: rootReducer
});
